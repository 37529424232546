<template>
  <div v-if="!loading" id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <bread-crumbs
          :context="{
            projectName: getCurrentProjectName,
            projectId: positionData.project,
            positionName: positionData.name,
          }"
        />
        <position-bio-panel :loading="loading" :project-choices="projectChoices" />
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <v-tabs v-model="positionTab" v-touch="() => {}" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.icon" v-touch="() => {}">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ $t(`${tab.title}`) }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-if="!loading" id="user-tabs-content" v-model="positionTab" class="mt-5 pa-1" touchless>
          <v-tab-item>
            <position-assignees-table v-if="positionData.id" @refetch-data="fetchPositionData" />
          </v-tab-item>

          <v-tab-item>
            <position-timesheet-table />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiAccountCancelOutline, mdiChevronLeft, mdiCalendarOutline } from '@mdi/js'
import { onMounted, onUnmounted, ref, computed, watch } from '@vue/composition-api'
import { useActions, useMutations, useState } from 'vuex-composition-helpers'
import { useRouter } from '@core/utils'
import router from '@/router'
// eslint-disable-next-line object-curly-newline
import PositionBioPanel from './position-bio-panel/PositionBioPanel.vue'
import PositionAssigneesTable from './position-assignees/PositionAssigneesTable.vue'
import PositionTimesheetTable from './position-timesheet/PositionTimesheetTable.vue'
import BreadCrumbs from '@/components/files-table/BreadCrumbs.vue'

export default {
  components: {
    BreadCrumbs,
    PositionBioPanel,
    PositionAssigneesTable,
    PositionTimesheetTable,
  },
  setup() {
    const tabs = [
      { icon: mdiAccountCancelOutline, title: 'assignees' },
      { icon: mdiCalendarOutline, title: 'timesheet' },
    ]

    const positionTab = ref(null)

    const projectChoices = ref([])
    const loading = ref(false)

    const { getPositionDetails, getProjectsChoices } = useActions('staff', ['getPositionDetails', 'getProjectsChoices'])
    const { positionData } = useState('staff', ['positionData'])
    const { SET_POSITION_DATA } = useMutations('staff', ['SET_POSITION_DATA'])

    const fetchPositionData = async () => {
      loading.value = true
      await getPositionDetails(router.currentRoute.params.id)
      loading.value = false
    }

    const { route } = useRouter()

    watch(
      () => route.value.params.id,
      async () => {
        await fetchPositionData()
      },
    )

    const getCurrentProjectName = computed(() => {
      return projectChoices.value.find((project) => project.value === positionData.value.project)?.text
    })

    onMounted(async () => {
      await fetchPositionData()
      projectChoices.value = await getProjectsChoices()
    })

    onUnmounted(() => {
      SET_POSITION_DATA({})
    })

    return {
      fetchPositionData,

      tabs,
      positionTab,
      getCurrentProjectName,
      positionData,
      projectChoices,
      loading,
      icons: {
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
