<template>
  <!-- edit profile dialog -->
  <v-dialog v-model="isBioDialogOpen" max-width="650px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> Edit position information </v-card-title>

      <v-card-text class="mt-5">
        <v-form ref="bioEditForm" class="multi-col-validation" @submit.prevent="handleFormSubmit">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="positionDataLocal.name"
                outlined
                dense
                maxlength="150"
                hide-details="auto"
                :label="$t('name')"
                :placeholder="$t('name')"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="positionDataLocal.demand"
                :label="$t('demand')"
                :placeholder="$t('demand')"
                outlined
                dense
                type="number"
                min="0"
                hide-details="auto"
                :rules="[validators.minPositiveValue, validators.nonEmptyValueValidator]"
              />
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                v-model="positionDataLocal.project"
                :items="projectChoices"
                outlined
                dense
                :label="$t('project')"
                :placeholder="$t('project')"
                :rules="[validators.required]"
              >
                <template v-if="positionDataLocal.project" #append-outer>
                  <v-fade-transition leave-absolute>
                    <v-icon color="primary" @click="goToProjectDetailsPage()">{{ icons.mdiOpenInNew }}</v-icon>
                  </v-fade-transition>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn
                v-t="'discard'"
                outlined
                color="secondary"
                class="me-3"
                @click.prevent="$emit('update:is-bio-dialog-open', false)"
              />
              <v-btn color="primary" type="submit" :loading="loading" :disabled="!areDataChanged">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required, minPositiveValue, nonEmptyValueValidator } from '@core/utils/validation'
import _ from 'lodash'
import { useRouter } from '@core/utils'
import { mdiOpenInNew } from '@mdi/js'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    positionData: {
      type: Object,
      required: true,
    },
    projectChoices: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)

    const bioEditForm = ref(null)

    const positionDataLocal = ref({})
    positionDataLocal.value = _.cloneDeep(props.positionData)

    const { updatePositionDetails } = useActions('staff', ['updatePositionDetails'])

    const handleFormSubmit = async () => {
      const isFormValid = bioEditForm.value.validate()
      if (!isFormValid) return

      loading.value = true

      const { success } = await updatePositionDetails(positionDataLocal.value)
      loading.value = false

      if (success) {
        emit('update:is-bio-dialog-open', false)
      }
    }

    watch(
      () => props.isBioDialogOpen,
      async (newVal) => {
        if (newVal) {
          positionDataLocal.value = _.cloneDeep(props.positionData)
        }
      },
    )

    const areDataChanged = computed(() => {
      return (
        positionDataLocal.value.name !== props.positionData.name ||
        positionDataLocal.value.demand !== props.positionData.demand ||
        positionDataLocal.value.project !== props.positionData.project
      )
    })

    const { router } = useRouter()

    const goToProjectDetailsPage = () => {
      if (!positionDataLocal.value.project) return
      const route = router.resolve({ name: 'apps-project-view', params: { id: positionDataLocal.value.project } })
      window.open(route.href, '_blank')
    }

    return {
      handleFormSubmit,
      goToProjectDetailsPage,

      loading,
      bioEditForm,
      positionDataLocal,
      areDataChanged,

      validators: {
        required,
        minPositiveValue,
        nonEmptyValueValidator,
      },

      icons: {
        mdiOpenInNew,
      },
    }
  },
}
</script>
