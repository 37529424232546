<template>
  <v-navigation-drawer
    :value="isExportSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
    app
    @input="(val) => openOrDiscard(val)"
  >
    <div class="drawer-header d-flex align-center">
      <span v-t="'export'" class="font-weight-semibold text-base text--primary" />
      <v-spacer></v-spacer>
      <v-btn icon small @click="$emit('update:is-export-sidebar-active', false)">
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <v-form v-if="isExportSidebarActive" ref="form" @submit.prevent="onSubmit">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-alert color="info" text>
              <p v-t="'exportAlert'" class="font-weight-semibold mb-1" />
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="columnNameLanguage"
              :label="$t('columnNameLanguage')"
              :items="[
                {
                  text: $t('english'),
                  value: 'en',
                },
                {
                  text: $t('polish'),
                  value: 'pl',
                },
              ]"
              :rules="[validators.required]"
              outlined
              dense
              hide-details="auto"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-switch v-model="dayMonthSwitch" hide-details :label="$t('dayMonthSwitch')" class="mt-0 pt-0" />
          </v-col>
          <div v-if="dayMonthSwitch" class="w-full">
            <v-col cols="12">
              <v-menu
                ref="monthlyDateRangeStartMenuRef"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :close-on-content-click="false"
              >
                <template v-slot:activator="activator">
                  <v-text-field
                    v-model="monthlyDateRangeStart"
                    :label="$t('monthlyDateRangeStart')"
                    readonly
                    outlined
                    dense
                    v-bind="activator.attrs"
                    hide-details="auto"
                    persistent-hint
                    :rules="[
                      validators.required,
                      validators.dateRangeValidator(monthlyDateRangeStart, monthlyDateRangeEnd),
                    ]"
                    v-on="activator.on"
                  />
                </template>
                <v-date-picker
                  v-model="monthlyDateRangeStart"
                  :first-day-of-week="1"
                  :locale="$i18n.locale"
                  type="month"
                  :min="positionDateRangeMin"
                  :max="positionDateRangeMax"
                  @change="saveMonthlyDateRangeStart"
                />
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-menu
                ref="monthlyDateRangeEndMenuRef"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :close-on-content-click="false"
              >
                <template v-slot:activator="activator">
                  <v-text-field
                    v-model="monthlyDateRangeEnd"
                    :label="$t('monthlyDateRangeEnd')"
                    readonly
                    outlined
                    dense
                    v-bind="activator.attrs"
                    hide-details="auto"
                    persistent-hint
                    :rules="[
                      validators.required,
                      validators.dateRangeValidator(monthlyDateRangeStart, monthlyDateRangeEnd),
                    ]"
                    v-on="activator.on"
                  />
                </template>
                <v-date-picker
                  v-model="monthlyDateRangeEnd"
                  :first-day-of-week="1"
                  :locale="$i18n.locale"
                  type="month"
                  :min="positionDateRangeMin"
                  :max="positionDateRangeMax"
                  @change="saveMonthlyDateRangeEnd"
                />
              </v-menu>
            </v-col>
          </div>
          <div v-else class="w-full">
            <v-col cols="12">
              <v-menu
                ref="dailyDateRangeStartMenuRef"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :close-on-content-click="false"
              >
                <template v-slot:activator="activator">
                  <v-text-field
                    v-model="dailyDateRangeStart"
                    :label="$t('dailyDateRangeStart')"
                    readonly
                    outlined
                    dense
                    v-bind="activator.attrs"
                    hide-details="auto"
                    persistent-hint
                    :rules="[
                      validators.required,
                      validators.dateRangeValidator(dailyDateRangeStart, dailyDateRangeEnd),
                    ]"
                    v-on="activator.on"
                  />
                </template>
                <v-date-picker
                  v-model="dailyDateRangeStart"
                  :first-day-of-week="1"
                  :locale="$i18n.locale"
                  :min="positionDateRangeMin"
                  :max="positionDateRangeMax"
                  @change="saveDailyDateRangeStart"
                />
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-menu
                ref="dailyDateRangeEndMenuRef"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :close-on-content-click="false"
              >
                <template v-slot:activator="activator">
                  <v-text-field
                    v-model="dailyDateRangeEnd"
                    :label="$t('dailyDateRangeEnd')"
                    readonly
                    outlined
                    dense
                    v-bind="activator.attrs"
                    hide-details="auto"
                    persistent-hint
                    :rules="[
                      validators.required,
                      validators.dateRangeValidator(dailyDateRangeStart, dailyDateRangeEnd),
                    ]"
                    v-on="activator.on"
                  />
                </template>
                <v-date-picker
                  v-model="dailyDateRangeEnd"
                  :first-day-of-week="1"
                  :locale="$i18n.locale"
                  :min="positionDateRangeMin"
                  :max="positionDateRangeMax"
                  @change="saveDailyDateRangeEnd"
                />
              </v-menu>
            </v-col>
          </div>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="overwritten-drawer-actions">
          <v-col cols="12" md="6">
            <v-btn color="primary" class="me-3" type="submit" block :loading="loading"> {{ $t('download') }}</v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn v-t="'discard'" color="secondary" outlined type="reset" block @click="openOrDiscard(false)" />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { getCurrentInstance, ref } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import { required, dateRangeValidator } from '@core/utils/validation'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import timesheetUtils from '@/utils/timesheetUtils'

export default {
  model: {
    prop: 'isExportSidebarActive',
    event: 'update:is-export-sidebar-active',
  },
  props: {
    isExportSidebarActive: {
      type: Boolean,
      required: true,
    },
    positionDateRangeMin: {
      type: String,
      default: null,
    },
    positionDateRangeMax: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const dayMonthSwitch = ref(false)

    const { saveDateHandler } = timesheetUtils()

    const monthlyDateRangeStart = ref(null)
    const monthlyDateRangeEnd = ref(null)
    const monthlyDateRangeStartMenuRef = ref(null)
    const monthlyDateRangeEndMenuRef = ref(null)

    const saveMonthlyDateRangeStart = (value) => {
      saveDateHandler(monthlyDateRangeStartMenuRef.value, value)
    }
    const saveMonthlyDateRangeEnd = (value) => {
      saveDateHandler(monthlyDateRangeEndMenuRef.value, value)
    }

    const dailyDateRangeStart = ref(null)
    const dailyDateRangeEnd = ref(null)
    const dailyDateRangeStartMenuRef = ref(null)
    const dailyDateRangeEndMenuRef = ref(null)

    const saveDailyDateRangeStart = (value) => {
      saveDateHandler(dailyDateRangeStartMenuRef.value, value)
    }
    const saveDailyDateRangeEnd = (value) => {
      saveDateHandler(dailyDateRangeEndMenuRef.value, value)
    }

    const reportFields = [
      {
        columnName: 'firstName',
        field: 'first_name',
      },
      {
        columnName: 'lastName',
        field: 'last_name',
      },
      {
        columnName: 'clientName',
        field: 'client_name',
      },
      {
        columnName: 'projectName',
        field: 'project_name',
      },
      {
        columnName: 'positionName',
        field: 'position_name',
      },
      {
        columnName: 'assignmentDateStart',
        field: 'assignee_date_start',
      },
      {
        columnName: 'assignmentDateEnd',
        field: 'assignee_date_end',
      },
      {
        columnName: 'exportDateStart',
        field: 'export_date_start',
      },
      {
        columnName: 'exportDateEnd',
        field: 'export_date_end',
      },

      // Sum must be last!
      {
        columnName: 'sum',
        field: 'sum',
      },
    ]

    const { generatePositionDailyTimesheetsReport, generatePositionMonthlyTimesheetsReport } = useActions('staff', [
      'generatePositionDailyTimesheetsReport',
      'generatePositionMonthlyTimesheetsReport',
    ])
    const { positionData } = useState('staff', ['positionData'])

    const columnNameLanguage = ref(vm.$i18n.locale)

    const loading = ref(false)
    const form = ref(null)

    const onSubmit = async () => {
      const isFormValid = form.value.validate()

      if (!isFormValid) return

      loading.value = true
      const fields = []
      const columnNames = []
      reportFields.forEach((field) => {
        fields.push(field.field)
        columnNames.push(vm.$t(field.columnName, columnNameLanguage.value, { postfix: '' }))
      })

      let data
      let fileName
      let success

      if (dayMonthSwitch.value) {
        ;({ data, fileName, success } = await generatePositionMonthlyTimesheetsReport({
          positionId: positionData.value.id,
          dateStart: monthlyDateRangeStart.value,
          dateEnd: monthlyDateRangeEnd.value,
          fields,
          columnNames,
        }))
      } else {
        ;({ data, fileName, success } = await generatePositionDailyTimesheetsReport({
          positionId: positionData.value.id,
          dateStart: dailyDateRangeStart.value,
          dateEnd: dailyDateRangeEnd.value,
          fields,
          columnNames,
        }))
      }

      if (success) {
        const anchor = document.createElement('a')
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`
        anchor.target = '_blank'
        anchor.download = fileName
        anchor.click()
        anchor.remove()
      }

      loading.value = false
      emit('update:is-export-sidebar-active', false)
    }

    const openOrDiscard = (valueToEmit) => {
      if (!valueToEmit) {
        form.value.reset()
        columnNameLanguage.value = vm.$i18n.locale
      }

      emit('update:is-export-sidebar-active', valueToEmit)
    }

    return {
      onSubmit,
      openOrDiscard,
      saveMonthlyDateRangeStart,
      saveMonthlyDateRangeEnd,
      saveDailyDateRangeStart,
      saveDailyDateRangeEnd,

      dayMonthSwitch,
      monthlyDateRangeStart,
      monthlyDateRangeEnd,
      monthlyDateRangeStartMenuRef,
      monthlyDateRangeEndMenuRef,
      dailyDateRangeStart,
      dailyDateRangeEnd,
      dailyDateRangeStartMenuRef,
      dailyDateRangeEndMenuRef,
      columnNameLanguage,
      form,
      loading,
      validators: { required, dateRangeValidator },

      icons: {
        mdiClose,
      },
      ACTIONS,
      PRIVATE,
    }
  },
}
</script>
