<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between align-center">
      {{ $t('assignees') }}
      <v-btn small color="primary" class="me-3" @click.stop="isAddAssigneeDrawerVisible = true">
        <v-icon class="me-2" left>{{ icons.mdiPlus }}</v-icon>
        <span v-t="'addAssignee'" />
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="tableColumns"
      :items="positionData.assignees"
      :group-by="$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES) ? 'group' : null"
      sort-by="lastName"
      :item-key="'id'"
      :hide-default-footer="true"
      :items-per-page="-1"
      disable-pagination
      disable-filtering
      :mobile-breakpoint="0"
    >
      <template #[`top`]>
        <v-dialog v-model="isDeleteDialogVisible" max-width="650px">
          <v-card class="pa-sm-10 pa-3">
            <v-card-title v-t="'deleteAssignee'" class="justify-center text-h5" />
            <v-card-text>
              <v-row>
                <v-col v-t="'confirmationModalUndoneQuestion'" cols="12" class="d-flex align-center justify-center" />
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-center mt-3">
                  <v-btn v-t="'discard'" outlined class="me-3" :disabled="deleteLoading" @click="discardDelete" />
                  <v-btn :loading="deleteLoading" color="error" @click="confirmDelete">{{ $t('delete') }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="isUpdateDialogVisible" max-width="650px">
          <v-card v-if="isUpdateDialogVisible" class="user-edit-info pa-sm-10 pa-3">
            <v-card-title v-t="'editAssignee'" class="justify-center text-h5" />
            <v-card-text class="mt-5">
              <v-form
                ref="updateAssigneeForm"
                class="multi-col-validation"
                :disabled="updateLoading"
                @submit.prevent="confirmUpdate"
              >
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="assigneeToUpdate.employee"
                      :items="employeesChoices"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('employee')"
                      :placeholder="$t('employee')"
                      :rules="[validators.required]"
                    >
                      <template v-if="assigneeToUpdate.employee" #append-outer>
                        <v-fade-transition leave-absolute>
                          <v-icon color="primary" @click="goToEmployeeDetailsPage(assigneeToUpdate.employee)">{{
                            icons.mdiOpenInNew
                          }}</v-icon>
                        </v-fade-transition>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="assigneeToUpdate.position"
                      :items="assigneePositionChoices"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('position')"
                      :placeholder="$t('position')"
                      :rules="[validators.required]"
                    >
                      <template v-if="assigneeToUpdate.position" #append-outer>
                        <v-fade-transition leave-absolute>
                          <v-icon color="primary" @click="goToPositionDetailsPage(assigneeToUpdate.position)">{{
                            icons.mdiOpenInNew
                          }}</v-icon>
                        </v-fade-transition>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <upgrade-alert-wrapper>
                      <template #blocked-features>
                        <v-col cols="12">
                          <v-menu
                            close-on-content-click
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                          >
                            <template v-slot:activator="activator">
                              <v-text-field
                                v-model="assigneeToUpdate.dateStart"
                                :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                                :label="$t('assignmentDateStart')"
                                readonly
                                outlined
                                dense
                                clearable
                                v-bind="activator.attrs"
                                hide-details="auto"
                                :rules="[
                                  validators.dateRangeValidator(assigneeToUpdate.dateStart, assigneeToUpdate.dateEnd),
                                ]"
                                v-on="activator.on"
                              />
                            </template>
                            <v-date-picker
                              v-model="assigneeToUpdate.dateStart"
                              :first-day-of-week="1"
                              :locale="$i18n.locale"
                            />
                          </v-menu>
                        </v-col>
                        <v-col cols="12">
                          <v-menu
                            close-on-content-click
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                          >
                            <template v-slot:activator="activator">
                              <v-text-field
                                v-model="assigneeToUpdate.dateEnd"
                                :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                                :label="$t('assignmentDateEnd')"
                                readonly
                                outlined
                                dense
                                clearable
                                v-bind="activator.attrs"
                                hide-details="auto"
                                :rules="[
                                  validators.dateRangeValidator(assigneeToUpdate.dateStart, assigneeToUpdate.dateEnd),
                                ]"
                                v-on="activator.on"
                              />
                            </template>
                            <v-date-picker
                              v-model="assigneeToUpdate.dateEnd"
                              :first-day-of-week="1"
                              :locale="$i18n.locale"
                            />
                          </v-menu>
                        </v-col>
                      </template>
                    </upgrade-alert-wrapper>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-3">
                    <v-btn v-t="'discard'" outlined color="secondary" class="me-3" @click.prevent="discardUpdate" />
                    <v-btn color="primary" type="submit" :loading="updateLoading">
                      {{ $t('save') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-navigation-drawer
          v-model="isAddAssigneeDrawerVisible"
          temporary
          touchless
          :right="!$vuetify.rtl"
          :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
          app
        >
          <div class="drawer-header d-flex align-center">
            <span v-t="'addAssignee'" class="font-weight-semibold text-base text--primary" />
            <v-spacer></v-spacer>
            <v-btn icon small @click="isAddAssigneeDrawerVisible = false">
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </div>
          <v-form ref="addAssigneeForm" @submit.prevent="confirmAdd">
            <v-card-text>
              <v-subheader v-t="'assigneeData'" />
              <v-autocomplete
                v-model="addAssigneeData.assignees"
                :items="employeesChoices"
                outlined
                chips
                clearable
                deletable-chips
                multiple
                hide-details="auto"
                :label="$t('employee')"
                :placeholder="$t('employee')"
                :rules="[validators.nonEmptyValueValidator]"
                class="mb-3"
              />
              <upgrade-alert-wrapper :form="false" class="mb-3">
                <template #blocked-features>
                  <v-menu
                    close-on-content-click
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                  >
                    <template v-slot:activator="activator">
                      <v-text-field
                        v-model="addAssigneeData.dateStart"
                        :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                        :label="$t('assignmentDateStart')"
                        readonly
                        outlined
                        dense
                        clearable
                        v-bind="activator.attrs"
                        hide-details="auto"
                        :rules="[validators.dateRangeValidator(addAssigneeData.dateStart, addAssigneeData.dateEnd)]"
                        class="mb-3"
                        v-on="activator.on"
                      />
                    </template>
                    <v-date-picker v-model="addAssigneeData.dateStart" :first-day-of-week="1" :locale="$i18n.locale" />
                  </v-menu>
                  <v-menu
                    :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                    close-on-content-click
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="activator">
                      <v-text-field
                        v-model="addAssigneeData.dateEnd"
                        :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                        :label="$t('assignmentDateEnd')"
                        readonly
                        outlined
                        dense
                        clearable
                        v-bind="activator.attrs"
                        hide-details="auto"
                        :rules="[validators.dateRangeValidator(addAssigneeData.dateStart, addAssigneeData.dateEnd)]"
                        class="mb-3"
                        v-on="activator.on"
                      />
                    </template>
                    <v-date-picker v-model="addAssigneeData.dateEnd" :first-day-of-week="1" :locale="$i18n.locale" />
                  </v-menu>
                </template>
              </upgrade-alert-wrapper>
            </v-card-text>
            <v-card-actions>
              <v-row class="overwritten-drawer-actions">
                <v-col cols="12" md="6">
                  <v-btn color="primary" class="me-3" type="submit" block :loading="addLoading">
                    {{ $t('add') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn v-t="'discard'" color="secondary" outlined type="reset" block @click="discardAdd" />
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-navigation-drawer>
      </template>

      <template #[`header.lastName`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.dateStart`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.dateEnd`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.actions`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #group.header="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-icon v-if="isOpen" class="mr-2" @click="toggle">{{ icons.mdiChevronUpCircleOutline }}</v-icon>
          <v-icon v-else-if="!isOpen" class="mr-2" @click="toggle">{{ icons.mdiChevronDownCircleOutline }}</v-icon>
          <span v-if="group === 'current'" v-t="'currentGroup'" class="font-weight-semibold" />
          <span v-else-if="group === 'future'" v-t="'futureGroup'" class="font-weight-semibold" />
          <span v-else-if="group === 'past'" v-t="'pastGroup'" class="font-weight-semibold" />
        </td>
      </template>

      <template #[`item.lastName`]="{ item }">
        <div class="d-flex align-center ms-3">
          <v-avatar
            :color="item.avatar ? '' : 'primary'"
            :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="32"
          >
            <v-img v-if="item.avatar" :src="require(`@/assets/images/avatars/${item.avatar}`)"></v-img>
            <span v-else class="font-weight-medium">{{ avatarText(`${item.firstName} ${item.lastName}`) }}</span>
          </v-avatar>

          <div class="d-flex flex-column ms-3">
            <router-link
              :to="{ name: 'apps-employee-view', params: { id: item.employee } }"
              class="font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              {{ item.firstName }} {{ item.lastName }}
            </router-link>
          </div>
        </div>
      </template>

      <template #[`item.dateStart`]="{ item }">
        {{ formatDateToMonthShort(item.dateStart, false) }}
      </template>

      <template #[`item.dateEnd`]="{ item }">
        {{ formatDateEndColumn(item) }}
      </template>

      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <v-tooltip bottom>
            <template #activator="tooltipActivator">
              <v-btn
                icon
                small
                color="error"
                v-bind="tooltipActivator.attrs"
                v-on="tooltipActivator.on"
                @click="deleteItem(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span v-t="'delete'" />
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn icon color="primary" small v-bind="attrs" @click="updateAssignee(item)" v-on="on">
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span v-t="'edit'" />
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiClose,
  mdiPlus,
  mdiPencilOutline,
  mdiChevronUpCircleOutline,
  mdiChevronDownCircleOutline,
  mdiBellOutline,
  mdiTrashCanOutline,
  mdiFileDocumentOutline,
  mdiOpenInNew,
  mdiChevronRight,
} from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'

import { avatarText, emptyValueFormatter, formatDateToMonthShort } from '@core/utils/filter'
import { required, dateRangeValidator, nonEmptyValueValidator } from '@core/utils/validation'
import _ from 'lodash'
import { useActions, useState } from 'vuex-composition-helpers'
import { useRouter } from '@core/utils'
import usePositionAssigneesTable from './usePositionAssigneesTable'
import employeeUtils from '@/utils/employeeUtils'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import UpgradeAlertWrapper from '@/components/UpgradeAlertWrapper.vue'

export default {
  components: {
    UpgradeAlertWrapper,
  },
  setup(props, { emit }) {
    const { deleteAssignee, updateAssigneeDetails, getAllEmployeesChoices, createAssignees } = useActions('staff', [
      'deleteAssignee',
      'updateAssigneeDetails',
      'getAllEmployeesChoices',
      'createAssignees',
    ])
    const { positionData } = useState('staff', ['positionData'])

    const employeesChoices = ref([])

    const { router } = useRouter()

    const { tableColumns, formatDateEndColumn } = usePositionAssigneesTable()

    const {
      fetchAssigneePositionChoices,

      assigneePositionChoices,
    } = employeeUtils()

    const goToEmployeeDetailsPage = (employeeId) => {
      if (!employeeId) return
      const route = router.resolve({ name: 'apps-employee-view', params: { id: employeeId } })
      window.open(route.href, '_blank')
    }

    const goToPositionDetailsPage = (positionId) => {
      if (!positionId) return
      const route = router.resolve({ name: 'apps-project-position-view', params: { id: positionId } })
      window.open(route.href, '_blank')
    }

    onMounted(async () => {
      employeesChoices.value = await getAllEmployeesChoices()
      await fetchAssigneePositionChoices()
    })

    // Add
    const addLoading = ref(false)
    const addAssigneeForm = ref(null)
    const addAssigneeData = ref({
      assignees: [],
      dateStart: null,
      dateEnd: null,
    })
    const isAddAssigneeDrawerVisible = ref(false)

    const confirmAdd = async () => {
      const isFormValid = addAssigneeForm.value.validate()
      if (!isFormValid) return

      addLoading.value = true

      const mappedAssigneesToCreate = addAssigneeData.value.assignees.map((assignee) => {
        return {
          employee: assignee,
          position: positionData.value.id,
          dateStart: addAssigneeData.value.dateStart,
          dateEnd: addAssigneeData.value.dateEnd,
        }
      })

      const { success } = await createAssignees(mappedAssigneesToCreate)
      if (success) {
        emit('refetch-data')
        isAddAssigneeDrawerVisible.value = false
        addAssigneeForm.value.reset()
        addAssigneeData.value = { assignees: [], dateStart: null, dateEnd: null }
      }
      addLoading.value = false
    }
    const discardAdd = () => {
      isAddAssigneeDrawerVisible.value = false
      addAssigneeForm.value.reset()
      addAssigneeData.value = {
        assignees: [],
        dateStart: null,
        dateEnd: null,
      }
    }

    // Delete
    const deleteLoading = ref(false)
    const itemToDeleteId = ref(null)
    const isDeleteDialogVisible = ref(false)

    const deleteItem = (itemId) => {
      itemToDeleteId.value = itemId
      isDeleteDialogVisible.value = true
    }
    const confirmDelete = async () => {
      if (itemToDeleteId.value === null) return

      deleteLoading.value = true

      const { success } = await deleteAssignee({
        assigneeId: itemToDeleteId.value,
      })
      if (success) {
        emit('refetch-data')
        itemToDeleteId.value = null
      }
      deleteLoading.value = false
      isDeleteDialogVisible.value = false
    }
    const discardDelete = () => {
      itemToDeleteId.value = null
      isDeleteDialogVisible.value = false
    }

    // Update
    const assigneeToUpdate = ref(null)
    const updateLoading = ref(false)
    const updateAssigneeForm = ref(null)
    const isUpdateDialogVisible = ref(false)

    const updateAssignee = (assignee) => {
      assigneeToUpdate.value = _.cloneDeep(assignee)
      isUpdateDialogVisible.value = true
    }
    const confirmUpdate = async () => {
      const isFormValid = updateAssigneeForm.value.validate()
      if (!isFormValid || assigneeToUpdate.value === null) return

      updateLoading.value = true

      const { success } = await updateAssigneeDetails({
        assigneeData: assigneeToUpdate.value,
      })
      if (success) {
        emit('refetch-data')
        assigneeToUpdate.value = null
      }
      updateLoading.value = false
      isUpdateDialogVisible.value = false
    }
    const discardUpdate = () => {
      isUpdateDialogVisible.value = false
      assigneeToUpdate.value = null
    }

    // ---------------

    return {
      // Add
      confirmAdd,
      discardAdd,
      addLoading,
      isAddAssigneeDrawerVisible,
      addAssigneeData,
      addAssigneeForm,

      // Delete
      deleteItem,
      confirmDelete,
      discardDelete,
      isDeleteDialogVisible,
      deleteLoading,

      // Update
      updateAssignee,
      confirmUpdate,
      discardUpdate,
      assigneeToUpdate,
      isUpdateDialogVisible,
      updateLoading,
      updateAssigneeForm,

      // ---------------

      employeesChoices,
      positionData,
      assigneePositionChoices,
      tableColumns,

      formatDateEndColumn,
      goToEmployeeDetailsPage,
      goToPositionDetailsPage,

      emit,
      avatarText,
      emptyValueFormatter,
      formatDateToMonthShort,

      validators: {
        required,
        dateRangeValidator,
        nonEmptyValueValidator,
      },

      icons: {
        mdiClose,
        mdiPlus,
        mdiPencilOutline,
        mdiChevronUpCircleOutline,
        mdiChevronDownCircleOutline,
        mdiBellOutline,
        mdiTrashCanOutline,
        mdiFileDocumentOutline,

        mdiOpenInNew,
        mdiChevronRight,
      },

      ACTIONS,
      PRIVATE,
    }
  },
}
</script>
